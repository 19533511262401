import { createAction } from "typesafe-actions";

import * as constants from "../constants";

export interface AuthUserPayload { name: string, bal: number }
export const authUser = createAction(constants.AUTH_USER,
    (name: string, bal: number): AuthUserPayload => ({ name, bal }))();

export interface LogoutUserPayload {}
export const logoutUser = createAction(constants.LOGOUT_USER,
    (): LogoutUserPayload => ({}))();

export interface UpdateBalancePayload { bal: number }
export const updateBalance = createAction(constants.UPDATE_BALANCE,
    (bal: number): UpdateBalancePayload => ({ bal }))();

export interface AddFriendsPayload { friends: string[] }
export const addFriends = createAction(constants.ADD_FRIENDS,
    (friends: string[]): AddFriendsPayload => ({ friends }))();

export interface RemoveFriendPayload { friend: string }
export const removeFriend = createAction(constants.REMOVE_FRIEND,
    (friend: string): RemoveFriendPayload => ({ friend }))();
