// Game State
// ---
export const START_GAME = "START_GAME";
export const BUST_GAME = "BUST_GAME";
export const LOAD_HISTORY = "LOAD_HISTORY";

// User State
// ---
export const AUTH_USER = "AUTH_USER";
export const UPDATE_BALANCE = "UPDATE_BALANCE";
export const LOGOUT_USER = "LOGOUT_USER";

export const ADD_FRIENDS = "ADD_FRIENDS";
export const REMOVE_FRIEND = "REMOVE_FRIEND";

// Chat State
// ---
export const CLEAR_DMS = "CLEAR_DMS";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";
export const RECEIVE_PRIVATE_MESSAGE = "RECEIVE_PRIVATE_MESSAGE";
export const READ_MESSAGES = "READ_MESSAGES";

// Players State
// ---
export const CLEAR_PLAYERLIST = "CLEAR_PLAYERLIST";
export const ADD_NET_WAGER = "ADD_NET_WAGER";
export const ADD_NET_WAGER_BULK = "ADD_NET_WAGER_BULK";
export const PLAYER_CASHEDOUT = "PLAYER_CASHEDOUT";
export const UPDATE_PLAYING = "UPDATE_PLAYING";
